import React from 'react'
import HRNavbar from '../../HR/HRNavbar'

const AccountDashboard = () => {
    return (
        <div>
            <HRNavbar />
            Accound Dashboard
        </div>
    )
}

export default AccountDashboard
