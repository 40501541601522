import React from 'react'
import HRNavbar from '../HR/HRNavbar'

const AdminDashboard = () => {
  return (
    <div>
      <HRNavbar />
      Dashboard Component
    </div>
  )
}

export default AdminDashboard
