import React from 'react';
import HRNavbar from '../HR/HRNavbar'

const DemoPurpose = () => {
    return (
        <div>
            <HRNavbar />
            Comming soon...!!
        </div>
    )
}

export default DemoPurpose
