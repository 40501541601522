import React from 'react';
import Navbar from '../../../Navbar';
import Footer from '../../../Footer';
import Header from '../../../Header';

const Membership = () => {
  return (
    <div>
      <Navbar/>
      {/* <Header /> */}
      <h4>Spero Membership</h4>
      <Footer />
    </div>
  )
}

export default Membership
