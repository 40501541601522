import React from 'react'
import HRNavbar from '../../HR/HRNavbar'

const HrDashboard = () => {
    return (
        <div>
            <HRNavbar />
        </div>
    )
}

export default HrDashboard
